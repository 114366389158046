<template>
  <svg
    width="55"
    height="55"
    viewBox="0 0 47 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :class="{
      'transform rotate-180': props.direction === 'next',
      'text-[#777777]': true,
      'hover:text-white duration-200 cursor-pointer': !disabled,
      'pointer-events-none': disabled,
      ...(props.class && { [props.class]: true })
    }"
  >
    <circle cx="23.5" cy="23.5" r="23" stroke="currentColor" :stroke-opacity="disabled ? 0.4 : 1" />
    <path
      d="M27 15L19 23L27 31"
      stroke="currentColor"
      stroke-width="1.75"
      :stroke-opacity="disabled ? 0.4 : 1"
      store
    />
  </svg>
</template>

<script setup lang="ts">
const props = defineProps<{
  direction: 'prev' | 'next'
  disabled?: boolean
  class?: string
}>()
</script>

<style scoped></style>
