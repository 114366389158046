<template>
  <swiper-container :id="getElementId('swiper')" :init="false" :class="props.class ?? ''">
    <swiper-slide
      v-for="(item, index) in props.items"
      :key="item.id"
      :virtual-index="item.id"
      style="width: auto"
    >
      <slot :item="item" :index="index"></slot>
    </swiper-slide>
  </swiper-container>
  <div v-show="showSwiperArrow" class="flex gap-x-4 select-none">
    <SwiperArrow :id="getElementId('prev-arrow')" direction="prev" :disabled="isFirstSlide" />
    <SwiperArrow :id="getElementId('next-arrow')" direction="next" :disabled="isLastSlide" />
  </div>
</template>

<script setup lang="ts" generic="T extends { id: string | number }">
import { register } from 'swiper/element/bundle'
import type { SwiperContainer } from 'swiper/element/bundle'
import type { SwiperOptions } from 'swiper/types'
import SwiperArrow from '~/components/icons/swiper-arrow.vue'
import 'swiper/css/pagination'
import { nextTick } from 'vue'

const props = withDefaults(
  defineProps<{
    id: string
    items: Array<T>
    class?: string
    showSwiperArrow?: boolean
    options?: SwiperOptions
  }>(),
  {
    showSwiperArrow: true
  }
)
const isFirstSlide = ref<boolean>(true)
const isLastSlide = ref<boolean>(false)

function getElementId(element: 'prev-arrow' | 'next-arrow' | 'swiper') {
  let suffix = ''

  switch (element) {
    case 'prev-arrow':
      suffix = 'prev-arrow'
      break
    case 'next-arrow':
      suffix = 'next-arrow'
      break
  }

  return `${props.id}${suffix ? '-' + suffix : ''}`
}

onMounted(() => {
  const swiper = document.querySelector(`#${props.id}`) as SwiperContainer
  if (swiper) {
    const swiperParams: SwiperOptions = {
      slidesPerView: 'auto',
      spaceBetween: 30,
      slidesOffsetBefore: 16,
      slidesOffsetAfter: 24,
      slidesPerGroup: 1,
      simulateTouch: true,
      breakpointsBase: 'container',
      breakpoints: {
        1024: {
          slidesOffsetBefore: 48,
          slidesOffsetAfter: 48
        },
        1280: {
          slidesOffsetBefore: 64,
          slidesOffsetAfter: 64
        }
      },
      navigation: {
        prevEl: `#${getElementId('prev-arrow')}`,
        nextEl: `#${getElementId('next-arrow')}`
      },
      on: {
        slideChange(swiper) {
          isFirstSlide.value = swiper.isBeginning
          isLastSlide.value = swiper.isEnd
        }
      },
      ...props.options
    }

    Object.assign(swiper, swiperParams)
    nextTick(() => {
      swiper.initialize()
    })
  }
})

register()
</script>

<style scoped></style>
